// import { PRIMARY_NAV_MOBILE_HEIGHT } from "design-system/nav-menu/nav-menu";
import { Box } from "design-system/components/box";
import { Button } from "design-system/components/button";
import { Text } from "design-system/components/text";
import Image from "next/image";
import { ReactNode } from "react";
import { css } from "styled-system/css";
import { styled } from "styled-system/jsx";

type ErrorPageProps = {
  title: string;
  description: ReactNode;
};

export const ErrorPage = ({ title, description }: ErrorPageProps) => (
  <ErrorPageWrapper className="flex-centralized">
    <Box className="flex-col align-center gap-32 pos-relative">
      <Box className="pos-relative">
        <Image
          src="/assets/shared/eagle.svg"
          height={128}
          width={270}
          alt="bird flying with open wings"
          className={css({
            height: "auto",
            left: "160px",
            margin: "0 auto",
            position: "absolute",
            right: "0",
            top: "-25px",
            width: "clamp(160px, 25vmin, 270px)",
          })}
        />
        <Image
          src="/assets/shared/eagle.svg"
          height={73}
          width={157}
          alt="small bird flying with open wings"
          className={css({
            position: "absolute",
            left: "0",
            top: "140px",
            margin: "auto",
            width: "clamp(120px, 20vmin, 160px)",
          })}
        />
        <ErrorHeading>{title}</ErrorHeading>
      </Box>

      <Text className="text-center" fontSize="xl">
        {description}
      </Text>
      <Button variant="ghost" as="a" href="/">
        Take me home
      </Button>
    </Box>
  </ErrorPageWrapper>
);

const ErrorPageWrapper = styled("main", {
  base: {
    backgroundColor: "white",
    flexDirection: "column",
    height: `calc(100vh - 72px)`,
  },
});

const ErrorHeading = styled("h1", {
  base: {
    color: "focusDark",
    fontFamily: "biennale",
    fontSize: "clamp(200px, 50vmin, 500px)",
    lineHeight: "clamp(200px, 50vmin, 500px)",
  },
});
